import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/subscription";

export const getSubscriber = (query) => {
    return axiosApiInstance.get(`${serverUrl}?${query}`);
};
export const downloadSubsciber = (query) => {
    return (`${serverUrl}/download?${query}`);
};


