import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/product";

export const addProduct = async (formData) => {
  // console.log(axiosApiInstance)
  return await axiosApiInstance.post(serverUrl + "/addProduct", formData);
};

export const getProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAdminAllProducts?${query}`);
};
export const getProductById = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${query}`);
};
export const getAdminAllProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAdminAllProducts?${query}`);
};

export const BulkProductUploadToBackend = (obj) => {
  return axiosApiInstance.post(`${serverUrl}/BulkProductUpload`, obj);
};

export const BulkProductUpdateToBackend = (obj) => {
  return axiosApiInstance.post(`${serverUrl}/BulkProductUpdate`, obj);
};

export const deleteProductById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getProductsAndAttributesAsProduct = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAttributesAsProductAndProducts?${query}`);
};

export const getAttributesAsProductAndProductsByToken = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAttributesAsProductAndProductsByToken?${query}`);
};

export const updateProductById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const approveProductsInBulk = (formData) => {
  return axiosApiInstance.patch(`${serverUrl}/approveProductsInBulk/`, formData);
};
export const unapproveProductsInBulk = (formData) => {
  return axiosApiInstance.patch(`${serverUrl}/unapproveProductsInBulk/`, formData);
};
export const deleteProductsInBulk = (formData) => {
  return axiosApiInstance.patch(`${serverUrl}/deleteProductsInBulk/`, formData);
};

export const handleRelatedProductObjAdd = (formData) => {
  return axiosApiInstance.post(`${serverUrl}/relatedProductsAdd`, formData);
};

export const handleRelatedProductObjRemove = (formData) => {
  return axiosApiInstance.post(`${serverUrl}/relatedProductsRemove`, formData);
};

export const getAllRelatedProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getRelatedProducts?${query}`);
};

export const sampleProductFileDownload = (obj) => {
  window.open(`${serverUrl}/downloadProductExcel?data=${obj}`);
};

export const sampleProductUpdateFileDownload = (obj) => {
  window.open(`${serverUrl}/downloadProductUpdateExcel?data=${obj}`);
};
