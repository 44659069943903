import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { BrandReducer } from "./Brand/Brand.reducer";
import { TaxReducer } from "./Tax/Tax.reducer";
import { BannerReducer } from "./Banner/Branner.reducer";
import { userReducer } from "./Users/users.reducer";
import { AttributeReducer } from "./Attribute/Attribute.reducer";
import { CategoryReducer } from "./Category/Category.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { SellerCategoryReducer } from "./SellerCategory/SellerCategory.reducer";
import { couponReducer } from "./Coupon/Coupon.reducer"
import { ContactReducer } from "./Contact/Contact.reducer";
import { blogReducer } from "./Blog/Blog.reducer";
import { seoReducer } from "./Seo/Seo.reducer";
import { ClientReducer } from "./Client/Client.reducer";
import { TestimonialReducer } from "./Testimonial/Testimonial.reducer"

const RootReducer = combineReducers({
  auth: authReducer,
  brand: BrandReducer,
  taxes: TaxReducer,
  banner: BannerReducer,
  client: ClientReducer,
  testimonial: TestimonialReducer,
  contact: ContactReducer,
  users: userReducer,
  attribute: AttributeReducer,
  category: CategoryReducer,
  product: ProductReducer,
  sellerCategory: SellerCategoryReducer,
  coupon: couponReducer,
  blog:blogReducer,
  seo:seoReducer

});
export default RootReducer;
