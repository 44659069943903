import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addUser, deleteUser, getUser, updateUser } from "../../services/users.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
import FileUpload from "../Utility/FileUpload";
import { addVerification, deleteVerification, getVerification, updateVerification } from "../../services/verification.service";


function Verification() {

  const navigate = useNavigate();
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();


  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [userArr, setUserArr] = useState([]);


  const [name, setname] = useState();
  const [image, setimage] = useState();






  const handleGetSelectedUSer = () => {
    return localStorage.getItem("selectedVerification")
  }
  const handleDeleteSelectedUser = () => {
    localStorage.removeItem("selectedVerification")
  }
  const handleEdit = (obj) => {
    setimage(obj?.image ? obj.image : "");
    setname(obj?.name ? obj?.name : "");
  }




  useEffect(() => {
    if (searchParams.get("id")) {
      let obj = handleGetSelectedUSer()
      if (obj) {
        obj = JSON.parse(obj);
      }

      handleEdit(obj)
    }
    // return () => { handleDeleteSelectedUser(); handleEdit({}) }
  }, [searchParams.get("id")])




  const blog_category_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    
    {
      name: "Brand",
      selector: (row) => row.brand,
    },
    {
      name: "date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon remove detail isRedirected={true} detailpath={`/Verfication/show-verifications?id=${row._id}`} deletePath="/Verification/verification" Uniquekey={row._id} onEditClick={() => { handleEdit(row); }} onDeleteClick={() => HandleDeleteUsers(row._id)} />
      ),
    },
  ];




  const HandleCardUserAdd = async () => {
    try {
      let obj = {
      }
      if (
        name == ""
      ) {
        toastError("Name cannot be empty !!!")
        return
      }
      if (
        image == ""
      ) {
        toastError("Image Upload !!!")
        return
      }

      if (name != "") {
        obj.name = name
      }

      if (image != "") {
        obj.image = image
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateVerification( searchParams.get("id"),obj)
        if (res.message) {
          toastSuccess(res.message);
          HandleGetUsers();
          handleEdit({});
          handleDeleteSelectedUser();
          navigate('/Verification/verification')
        }
      }
      else {
        let { data: res } = await addVerification(obj)
        if (res.message) {
          toastSuccess(res.message);
          HandleGetUsers();
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }





  const HandleGetUsers = async () => {
    try {

      let query = `admin=false&skip=${page * limit}&limit=${limit}`
      if (searchQuery && searchQuery != "") {
        query = `${query}&q=${searchQuery}`
      }



      setLoading(true)
      let { data: res } = await getVerification(query)
      if (res.data) {
        setUserArr(res.data)
        setLoading(false)
        setTotalElements(res.total);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const HandleDeleteUsers = async (id) => {
    if (window.confirm("Are you sure you want to delete this user , once deleted the data cannot be restored") == true) {
      try {
        let { data: res } = await deleteVerification(id)
        if (res.message) {
          HandleGetUsers()
        }
      }
      catch (err) {
        toastError(err)
      }
    }
  }



  useEffect(() => {
    HandleGetUsers()
  }, [limit, page, searchQuery])



  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handlePageChange = (page) => {
    console.log(page, "handlePageChange");
    if (totalElements / page > 1) {
      setPage(page - 1);
    }
  };


  const debouncedSave = useCallback(
    // skipValue, limitValue, filterCondition, searchQueryValue, school, company
    debounce(
      (nextValue) =>
        HandleGetUsers(),
      1000
    ),

    [searchQuery] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setSearchQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
       
            <div className="col-12 col-md-12">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Users List</h5>
                <SearchBox value={searchQuery} onChange={(e) => handleChange(e.target.value)} extraClass='bg-white' />
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_category_columns}
                  data={userArr}
                  pagination
                  progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalElements}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Verification;
