import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/cardLinkCategory";


export const addCardLinkCategory = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addCardLinkCategory", formData);
};

export const getCardLinkCategory = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getCardLinkCategory?${query}`);
};
export const updateCardLinkCategory = (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateCardLinkCategory/${id}`, obj);
};



export const getBySlug = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getBySlug/${id}`);
};
export const deleteCardLinkCategory = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
