import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ATTRIBUTEAdd, ATTRIBUTEGet, ATTRIBUTEUpdate } from "../../../redux/actions/Attribute/Attribute.actions";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
function AddAttribute() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedAttributeIdArr, setSelectedAttributeIdArr] = useState([]);

  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const attributeValueArr = useSelector((state) => state.attribute.attributeValues);

  const attributeObj = useSelector((state) => state.attribute.attributeObj);
  const [selectedAttributeId, setSelectedAttributeId] = useState("");
  const handleSubmit = () => {

    let obj = {
      name,
      description,
      attributeValueArr:  selectedAttributeIdArr.map((el) => ({ attributeId: el._id,_id:el.valueId })),
    };

    console.log(obj,"objobjobj")
    if (selectedAttributeId != "") {
      let obj = {
        name,
        description,
             attributeValueArr:  selectedAttributeIdArr.map((el) => ({ attributeId: el._id,_id:el.valueId })),
      };
      handleReset();
      dispatch(ATTRIBUTEUpdate(obj, selectedAttributeId));
    } else {
      let obj = {
        name,
        description,
             attributeValueArr:  selectedAttributeIdArr.map((el) => ({ attributeId: el._id,_id:el.valueId })),
      };
      handleReset();
      // console.log(obj);
      dispatch(ATTRIBUTEAdd(obj));
    }
  };

  const handleReset = () => {
    setName("");
    setDescription("");
    setSelectedAttributeIdArr([]);
    setSelectedAttributeId("");
    dispatch(ATTRIBUTEGet());
  };

  useEffect(() => {
    dispatch(ATTRIBUTEGet());
  }, []);

  const handleAttributeValueChange = (e) => {
    console.log(e);
    setSelectedAttributeIdArr(e);
  };

  useEffect(() => {
    if (attributeObj && attributeObj?._id) {
      setName(attributeObj?.name);
      setDescription(attributeObj?.description);
      setSelectedAttributeIdArr(attributeObj?.attributeValueArr.map((el) => ({ ...el, _id: el.attributeId,valueId:el._id })));
      setSelectedAttributeId(attributeObj?._id);
    }
  }, [attributeObj]);

  return (
    <form action="#" className="form row">
      <div className="col-12">
        <label className="blue-1 fs-12">
          Name <span className="red">*</span>
        </label>
        <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">DESCRIPTION</label>
        <textarea value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" rows="5"></textarea>
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">Attribute</label>
        <Select onChange={handleAttributeValueChange} value={selectedAttributeIdArr} isMulti options={attributeValueArr && attributeValueArr.length > 0 ? attributeValueArr.map((el) => ({ ...el, label: el.name, value: el._id })) : []} />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">Status</label>
        <div className="d-flex">
          <div className="form-check form-check-inline d-flex align-items-center">
            <input className="form-check-input" type="radio" name="category-status" value={status} checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} id="category-Radio1" />
            <label className="form-check-label fs-14" htmlFor="category-Radio1">
              Active
            </label>
          </div>
          <div className="form-check form-check-inline d-flex align-items-center">
            <input className="form-check-input" type="radio" name="category-status" value={status} checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} id="category-Radio2" />
            <label className="form-check-label fs-14" htmlFor="category-Radio2">
              Inactive
            </label>
          </div>
        </div>
      </div>
      {selectedAttributeId ? (
        <div className="col-12 mt-2">
          <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Update" />
        </div>
      ) : (
        <div className="col-12 mt-2">
          <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
        </div>
      )}
    </form>
  );
}

export default AddAttribute;
