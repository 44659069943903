import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/template";


export const addTemplate = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addTemplate", formData);
};

export const getTemplate = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getTemplate?${query}`);
};
export const updateTemplate = (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateTemplate/${id}`, obj);
};



export const getBySlug = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getBySlug/${id}`);
};
export const deleteTemplate = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
