import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../components/Auth/Login";
import Footer from "../components/Layout/Footer";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import Profile from "../components/Profile/Profile";
import Profile1 from "../components/Profile1/Profile";
import UserDelete from "../components/Auth/UserDelete.jsx";

export default function UnauthorizedRoutes() {
  const location = useLocation();
  return (
    <section className="body_bg">
      <div className="row">
        {location.pathname.includes("profile") ?
          <Routes>
            <Route path="/profile/:slug" element={<Profile1 />}></Route>
          </Routes>
          :
          <>
            <div
              className="col-12"
              style={{
                height: "100vh",
                overflow: "hidden scroll",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Routes>
              <Route  path="/user-delete" element={<UserDelete Unauth />}></Route>
                <Route exact path="/" element={<Login Unauth />}></Route>
              </Routes>
              <Footer />
            </div>
          </>
        }
      </div>
    </section>
  );
}
