import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/Order";

export const getAllOrders = () => {
  return axiosApiInstance.get(serverUrl + "/getOrders");
};

export const getOrderById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const getInvoiceOrderById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getInvoiceById/${id}`);
};
export const assignOrderToDelhiveryApi = (id) => {
  return axiosApiInstance.get(`${serverUrl}/assignOrderToDelhivery/${id}`);
};

export const trackOrderToApi = (id) => {
  return axios.get(`${serverUrl}/trackOrder/${id}`);
};

export const updateOrderById = async (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};


export const getAccountLedger = () => {
  return axiosApiInstance.get(serverUrl + "/getAccountLeder");
};


export const downloadOrder = (query) => {
  return (`${serverUrl}/download?${query}`);
};
