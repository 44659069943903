import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/page";

export const addPage = (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getPage = (query) => {
  return axiosApiInstance.get(`${serverUrl}/`);
};

export const deletePage = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updatePage = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const getPageById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const getPageBySlug = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getPageBySlug/${id}`);
  };
