import React, { useState, useEffect } from "react";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { useSelector, useDispatch } from "react-redux";
import { rolesObj } from "../../utils/roles";
import { usersGet } from "../../redux/actions/Users/users.actions";
import Select from "react-select";
import { toastError } from "../../utils/toastUtils";
import { BulkProductUpdateToBackend, BulkProductUploadToBackend, getAttributesAsProductAndProductsByToken, sampleProductFileDownload, sampleProductUpdateFileDownload } from "../../services/product.service";
import { PRODUCTGet } from "../../redux/actions/Product/Product.actions";
import { cloneDeep } from "lodash";
import { toastSuccess } from "../Utility/ToastUtils";
function BulkProductUpdate() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userArr = useSelector((state) => state.users.users);
  const [isSeller, setIsSeller] = useState(false);
  const [selectedSellerObj, setSelectedSellerObj] = useState({});
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [file, setFile] = useState("");

  const [displayProductArr, setDisplayProductArr] = useState([]);

  const handleGetProducts = async () => {
    try {
      const { data: res } = await getAttributesAsProductAndProductsByToken();
      if (res) {
        setDisplayProductArr([...res.data.map((el) => ({ ...el, label: `${el.name}`, value: `${el?._id} | ${el.variantId}` }))]);
      }
    } catch (error) {
      toastError(error);
    }
  };
  useEffect(() => {
    handleGetProducts();
  }, []);

  const handleSampleFileDownload = async () => {
    try {
      if (selectedProductArr && selectedProductArr?.length > 0) {
        let encoded = selectedProductArr.map((el) => ({ productId: el._id, variantId: el.variantId }));
        await sampleProductUpdateFileDownload(JSON.stringify(encoded));
      } else {
        toastError({ message: "Please Select Product" });
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmitBulkUploadProduct = async () => {
    try {
      if (!file) {
        toastError("File not found ! please select file");
      } else if (file == "") {
        toastError("File not found !, please select file");
      }
      let formData = new FormData();
      formData.append("excel", file);
      let { data: res } = await BulkProductUpdateToBackend(formData);
      if (res) {
        toastSuccess(res.message);
      }
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <div className="container-fluid p-0">
      <DashboardBox>
        <h5 className="blue-1 mb-4">Bulk Product Update</h5>
        <form action="" className="form row">
          {/* {!isSeller && (
            <div className="col-12 col-md-6 mb-3">
              <label>
                Select Products <span className="red">*</span>
              </label>
              {displayProductArr && displayProductArr.length > 0 && (
                <Select isMulti onChange={(e) => setSelectedProductArr(e)} value={selectedProductArr} options={displayProductArr && displayProductArr.length > 0 ? displayProductArr : []} />
              )}
            </div>
          )} */}
          <div className="col-12 mb-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <label>CSV FILE</label>
              {/* <CustomButton isBtn btntype="button" ClickEvent={() => handleSampleFileDownload()} downloadAble noIcon changeClass="btn p-0 m-0 fs-12 text-primary border-0" btnName="SAMPLE FILE DOWNLOAD" /> */}
            </div>
            <FileUpload
              returnOriginal={true}
              onFileChange={(e) => {
                setFile(e);
                console.log(e, "e");
              }}
            />
            <div className="form-text fs-12 blue-1">PLEASE DOWNLOAD THE SAMPLE FILE INPUT YOUR DESIRE INFORMATION THEN UPLOAD. DON'T TRY TO UPLOAD DIFFERENT FILE FORMAT AND INFORMATION</div>
          </div>
          <div className="col-12 text-center">
            <CustomButton btntype="button" ClickEvent={() => handleSubmitBulkUploadProduct()} isBtn iconName="fa-solid fa-check" btnName="Save" />
          </div>
        </form>
      </DashboardBox>
    </div>
  );
}

export default BulkProductUpdate;
