import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllOrders } from "../../../services/order.service";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import {
  Order_Delivery_Filter_Arr,
  Order_Delivery_Filter_Obj,
  Order_Payment_Filter_Arr,
  Order_Payment_Filter_Obj,
  Order_Payment_Status_Filter_Arr,
  Order_Payment_Status_Filter_Obj,
  MainOrderStatus,
  MainOrderStatusArr,
} from "../../Utility/utils";
// import moment from "moment";
function PendingOrder({ name }) {
  const [orders, setOrders] = useState([]);
  const [selectedOrderDeliveryFilter, setSelectedOrderDeliveryFilter] = useState({ label: Order_Delivery_Filter_Obj.Shiprocket, value: Order_Delivery_Filter_Obj.Shiprocket });
  const [selectedOrderPaymentFilter, setSelectedOrderPaymentFilter] = useState({ label: Order_Payment_Filter_Obj.COD, value: Order_Payment_Filter_Obj.COD });
  const [selectedOrderPaymentStatusFilter, setSelectedOrderPaymentStatusFilter] = useState({ label: Order_Payment_Status_Filter_Obj.PAID, value: Order_Payment_Status_Filter_Obj.UNPAID });

  const [selectedOrderStatusObj, setSelectedOrderStatusObj] = useState({ label: MainOrderStatus.ORDERED, value: MainOrderStatus.ORDERED });

  const navigate = useNavigate();
  const getOrder = async () => {
    try {
      const { data: res } = await getAllOrders();
      if (res) {
        console.log(res.data);
        setOrders(res.data.filter((el) => el.orderStatus == MainOrderStatus.ORDERED));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const PendingOrder_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Date",
    //   selector: (row) => moment(row?.createdAt).format('DD-MMM-YY HH:MM'),
      selector: (row) =>new Date(row?.createdAt).toDateString(),
    },
    {
      name: "Order ID",
      selector: (row) => row?.orderId,
      cell: (row) => row?.orderId,
      width: "200",
    },
    {
      name: "Name",
      selector: (row) =>     row?.addressObj?.firstName   +' '+    row?.addressObj?.lastName 
    },
    {
      name: "Payment Type",
      selector: (row) => row?.paymentMethod == 'COD'?'COD':'Online',
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
    },
    {
      name: "Order Status",
      button: true,
      width: "10%",
      cell: (row) => <a className="btn btn-orderd  "  href='#' > {row.orderStatus}</a>,
    },
    {
      name: "Is Paid",
      button: true,
      width: "10%",
      cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
    },
    {
      name: "Action",
      cell: (row) => <CustomButton isLink btntype="button" path={`/Order/Sale-Detail?id=${row?._id}`} btnName={"View"} />,
    },
  ];
  const handleSortFilter = (obj) => {
    setSelectedOrderDeliveryFilter(obj);
  };

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">{name}</h5>
        {/* <SearchBox extraClass="bg-white" /> */}
        <div className="d-flex gap-3 justify-content-between mb-4">
          <div className="m-2">
            <Select placeholder="Sort" options={Order_Delivery_Filter_Arr} value={selectedOrderDeliveryFilter} onChange={(e) => handleSortFilter(e)} />
          </div>
          <div className="m-2">
            <Select placeholder="Sort" options={Order_Payment_Filter_Arr} value={selectedOrderPaymentFilter} onChange={(e) => setSelectedOrderPaymentFilter(e)} />
          </div>
          <div className="m-2">
            <Select placeholder="Sort" options={Order_Payment_Status_Filter_Arr} value={selectedOrderPaymentStatusFilter} onChange={(e) => setSelectedOrderPaymentStatusFilter(e)} />
          </div>
          <div className="m-2">
            <Select placeholder="Sort" options={MainOrderStatusArr} value={selectedOrderStatusObj} onChange={(e) => setSelectedOrderStatusObj(e)} />
          </div>
        </div>
      </div>
      <DataTable columns={PendingOrder_columns} data={orders} pagination />
    </DashboardTable>
  );
}

export default PendingOrder;
