import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addUser, deleteUser, getUser, updateUser } from "../../services/users.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { rolesObj } from "../../utils/roles";
import Select from "react-select";
import { useSelector } from "react-redux";

function AddUser() {

  const navigate = useNavigate();
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  let user = useSelector((state) => state.auth.user);


  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [userArr, setUserArr] = useState([]);
  const [role, setRole] = useState(rolesObj.USER);
  
  const [password, setPassword] = useState("");
  const [cardNo, setCardNo] = useState(0);
  

  const [name, setname] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [rolesArr, setRolesArr] = useState([
    { value: "USER", label: "USER" },
    { value: "COMPANY", label: "COMPANY" },
  ]);





  const handleGetSelectedUSer = () => {
    return localStorage.getItem("selectedUser")
  }
  const handleDeleteSelectedUser = () => {
    localStorage.removeItem("selectedUser")
  }
  const handleEdit = (obj) => {
    setphone(obj?.phone ? obj.phone : "");
    setemail(obj?.email ? obj.email : "");
    setname(obj?.name ? obj?.name : "");

    localStorage.setItem("selectedUser", JSON.stringify(obj))
  }




  useEffect(() => {
    if (searchParams.get("id")) {
      let obj = handleGetSelectedUSer()
      if (obj) {
        obj = JSON.parse(obj);
      }

      handleEdit(obj)
    }
    // return () => { handleDeleteSelectedUser(); handleEdit({}) }
  }, [searchParams.get("id")])




  const blog_category_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionIcon remove edit isRedirected={true} editPath={`/User/users?id=${row._id}`} deletePath="/User/users" Uniquekey={row._id} onEditClick={() => { handleEdit(row); }} onDeleteClick={() => HandleDeleteUsers(row._id)} />
      ),
    },
  ];




  const HandleCardUserAdd = async () => {
    try {
      let obj = {
      }
      if (
        name == ""
      ) {
        toastError("Name cannot be empty !!!")
        return
      }
      if (
        email == ""
      ) {
        toastError("Email cannot be empty !!!")
        return
      }

      if (name != "") {
        obj.name = name
      }
      obj.phone = phone
      obj.email = email
      obj.role = role

      if(password){
      obj.password = password
      }

      if(user.role == rolesObj.COMPANY){
          obj.role = rolesObj.EMPLOYEE
          obj.companyId = user?._id;
      }

      if(cardNo){
        obj.totalCard = cardNo
        }

      if (searchParams.get("id")) {
        let { data: res } = await updateUser(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message);
          HandleGetUsers();
          handleEdit({});
          handleDeleteSelectedUser();
          navigate('/User/users')
        }
      }
      else {
        let { data: res } = await addUser(obj)
        if (res.message) {
          toastSuccess(res.message);
          HandleGetUsers();
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }





  const HandleGetUsers = async () => {
    try {

      console.log("useruseruseruseruseruseruseruser",user,role)

      let query = `admin=false&skip=${page * limit}&limit=${limit}`


     
      if (searchQuery && searchQuery != "") {
        query = `${query}&q=${searchQuery}`
      }



      setLoading(true)
      let { data: res } = await getUser(query)
      if (res.data) {
        setUserArr(res.data)
        setLoading(false)
        setTotalElements(res.total);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const HandleDeleteUsers = async (id) => {
    if (window.confirm("Are you sure you want to delete this user , once deleted the data cannot be restored") == true) {
      try {
        let { data: res } = await deleteUser(id)
        if (res.message) {
          HandleGetUsers()
        }
      }
      catch (err) {
        toastError(err)
      }
    }
  }



  useEffect(() => {
    HandleGetUsers()
  }, [limit, page, searchQuery])



  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handlePageChange = (page) => {
    console.log(page, "handlePageChange");
    if (totalElements / page > 1) {
      setPage(page - 1);
    }
  };


  const debouncedSave = useCallback(
    // skipValue, limitValue, filterCondition, searchQueryValue, school, company
    debounce(
      (nextValue) =>
        HandleGetUsers(),
      1000
    ),

    [searchQuery] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setSearchQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">{searchParams.get("id") ? "Edit" : "Add"} User</h5>
              <DashboardBox>
                <form className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      name<span className="red">*</span>
                    </label>
                    <input onChange={(e) => setname(e.target.value)} value={name} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      Phone
                    </label>
                    <input onChange={(e) => setphone(e.target.value)} value={phone} type="number" className="form-control" maxLength={10} />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      email <span className="red">*</span>
                    </label>
                    <input onChange={(e) => setemail(e.target.value)} value={email} type="text" className="form-control" />
                  </div>
                {
                     user?.role == rolesObj.ADMIN && (
                      <div className="col-12 mb-3">
                      <label className="blue-12 fs-12">
                        Role<span className="red">*</span>
                      </label>
                      <Select onChange={(e) => setRole(e.value)} options={rolesArr} />
                    </div>
                     )
                }
                
                  {
                      user?.role == rolesObj.ADMIN  && role == rolesObj.COMPANY &&  (
                      <>
                       <div className="col-12 mb-3">
                      <label className="blue-12 fs-12">
                        Password<span className="red">*</span>
                      </label>
                      <input onChange={(e) => setPassword(e.target.value)} value={password} type="text" className="form-control" />
                    </div>

                        <div className="col-12 mb-3">
                      <label className="blue-12 fs-12">
                        Card No<span className="red">*</span>
                      </label>
                      <input onChange={(e) => setCardNo(e.target.value)} value={cardNo} type="text" className="form-control" />
                    </div>
                      </>
                     
                    )
                  }
                  <div className="col-12">
                    {
                      searchParams.get("id") &&
                      <CustomButton
                        isBtn
                        extraClass={"me-3"}
                        btntype={"button"}
                        ClickEvent={() => { handleEdit({}); handleDeleteSelectedUser(); navigate('/User/users') }}
                        iconName="fa-solid fa-close"
                        btnName="Clear"
                      />
                    }
                    <CustomButton
                      isBtn
                      btntype={"button"}
                      ClickEvent={() => HandleCardUserAdd()}
                      iconName={searchParams.get("id") ? "fa-solid fa-edit" : "fa-solid fa-check"}
                      btnName={searchParams.get("id") ? "EDIT" : "ADD"}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Users List</h5>
                <SearchBox value={searchQuery} onChange={(e) => handleChange(e.target.value)} extraClass='bg-white' />
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_category_columns}
                  data={userArr}
                  pagination
                  // progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalElements}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AddUser;
