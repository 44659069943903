
import { Tooltip } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { addCard, deleteCard, getCard, updateCard } from "../../services/card.service";
import { frontEndUrl, url } from "../../services/url.service";
import { downloadQr, getUser } from "../../services/users.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
import {  getCardLink } from "../../services/cardLink.service";
import { images } from "../Images/Images";
import { ROLE_ADMIN, rolesObj } from "../../utils/roles";
import { useSelector } from "react-redux";

function CardAdd() {
  const [userArr, setUserArr] = useState([]);
  const [linkArr, setLinkArr] = useState([]);
  let role = useSelector((state) => state.auth.role);
  let user = useSelector((state) => state.auth.user);

  const [cardLinkArr, setCardLinkArr] = useState([{
  link:{
    label:"",
    value:""
  },
    name:"",
    value:"",
    type:"text"
  }]);
  const navigate = useNavigate();
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();


  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [cardArr, setCardArr] = useState([]);


  const [company, setCompany] = useState("");
  const [post, setPost] = useState("");

  const [cardNumber, setcardNumber] = useState();
  const [image, setimage] = useState();
  const [businessImage, setbusinessImage] = useState("");
  const [businessCover, setbusinessCover] = useState("");
  const [name, setname] = useState();
  const [password, setpassword] = useState();
  const [description, setdescription] = useState();
  const [facebook, setfacebook] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [twitter, settwitter] = useState();
  const [instagram, setinstagram] = useState();
  const [linkedIn, setlinkedIn] = useState();
  const [whatsapp, setwhatsapp] = useState();
  const [isActive, setisActive] = useState(false);
  const [isLocked, setisLocked] = useState(false);
  const [isVerfiy, setisVerfiy] = useState(false);


  const HandleGetUser = async () => {
    try {

      let query = `admin=false&skip=${0 * 10000}&limit=${10000}`
      if (searchQuery && searchQuery != "") {
        query = `${query}&q=${searchQuery}`
      }

      setLoading(true)
      let { data: res } = await getUser(query)
      if (res.data) {
        let tempArr = res.data;
        tempArr.unshift({name:"None",value:''});
        setUserArr(tempArr)
        setLoading(false)
        setTotalElements(res.total);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }


  useEffect(() => {
    HandleGetUser()
  }, [])


    const HandleGetLinks = async () => {
    try {

      let query = `admin=false&skip=${0 * 10000}&limit=${10000}`
      if (searchQuery && searchQuery != "") {
        query = `${query}&q=${searchQuery}`
      }

      setLoading(true)
      let { data: res } = await getCardLink(query)
      if (res.data) {
        setLinkArr(res.data)
        setLoading(false)
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }


  useEffect(() => {
    HandleGetLinks()
  }, [])



  const handleGetSelectedUSer = () => {
    return localStorage.getItem("selectedUser")
  }
  const handleDeleteSelectedUser = () => {
    localStorage.removeItem("selectedUser")
  }
  const handleEdit = (obj) => {

    setUserId(obj?.userId ? obj.userId : "");
    setcardNumber(obj?.cardNumber ? obj.cardNumber : "");
    setCompany(obj?.company ? obj.company : "");
    setPost(obj?.post ? obj.post : "");
    setdescription(obj?.description ? obj.description : "");
    setimage(obj?.image ? obj.image : "");
    setbusinessImage(obj?.businessImage ? obj.businessImage : "");
    setbusinessCover(obj?.businessCover ? obj.businessCover : "");
    setname(obj?.name ? obj.name : "");

    setphone(obj?.phone ? obj.phone : "");
    if(obj?.email){
    setemail(obj?.email ? obj?.email : "");
    }

    setCardLinkArr(obj?.cardLinkArr?.length > 0 ? obj.cardLinkArr : [{
  link:{
    label:"",
    value:""
  },
    name:"",
    value:"",
    type:"text"
  }]);
    setisActive(obj?.isActive);
    setisLocked(obj?.isLocked);
    setisVerfiy(obj?.isVerfiy);
    localStorage.setItem("selectedUser", JSON.stringify(obj))
  }




  useEffect(() => {
    if (searchParams.get("id")) {
      let obj = handleGetSelectedUSer()
      if (obj) {
        obj = JSON.parse(obj);
      }

      handleEdit(obj)
    }
    // return () => { handleDeleteSelectedUser(); handleEdit({}) }
  }, [searchParams.get("id")])

  const handleDownloadQrCode = async (userObj) => {
    try {
      console.log(userObj, "userObj");
      let { data: res } = await downloadQr(`url=${frontEndUrl}/profile/${userObj.slug}`)
      if (res.message) {
        console.log(res, "downlaod")
        downloadURI(`${url}/${res.file.fileName}`, res.file.fileName);
        // setLoading(false)
        // setTotalElements(res.total);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }

  }



  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    // link.download =


    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }
  const blog_category_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "IMAGE",
      cell: (row) => (
        row.image != ""    ?   <img height="84px" width="56px" alt={generateFilePath(row.image)} src={generateFilePath(row.image)} /> :  <img height="84px" width="56px" src={images.avatar} />
      ),
    },
    {
      name: "Card Number",
      selector: (row) => row.cardNumber,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Download Qr",
      cell: (row) => (
        <Tooltip title="Download Qr" placement="top" arrow>
          <button className='btn btn-dark' onClick={() => handleDownloadQrCode(row)}><i class="fa-solid fa-download"></i></button>
        </Tooltip>
      ),
    },

    {
      name: "Insi",
      cell: (row) => (
      
          <a href={`/insight/${row._id}`} className='btn btn-dark text-white'><i class="fa fa-bar-chart"></i></a>
          // <a href={`/profile/${row.slug}`} className='btn btn-dark text-white'><i class="fa fa-bar-chart"></i></a>
        
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionIcon remove  edit isRedirected={true}  editPath={`/User/cards?id=${row._id}`} deletePath="/User/cards" Uniquekey={row._id} onEditClick={() => { handleEdit(row); }} onDeleteClick={() => HandleDeleteUsers(row._id)} />
      ),
    },
  ];




  const HandleCardUserAdd = async () => {
    try {
      let obj = {
      }
      // if (
      //   cardNumber == ""
      // ) {
      //   toastError("Card number cannot be empty !!!")
      //   return
      // }
      // if (
      //   userId == ""
      // ) {
      //   toastError("User cannot be empty !!!")
      //   return
      // }
      // if (
      //   name == ""
      // ) {
      //   toastError("Name cannot be empty !!!")
      //   return
      // }
      // if (
      //   email == ""
      // ) {
      //   toastError("Email cannot be empty !!!")
      //   return
      // }

      if (cardNumber != "") {
        obj.cardNumber = cardNumber
      }
      if (image != "") {
        obj.image = image
      }

      if (businessImage != "") {
        obj.businessImage = businessImage
      }

      if (businessCover != "") {
        obj.businessCover = businessCover
      }


      if (image != "") {
        obj.image = image
      }
      if (name != "") {
        obj.name = name
      }
      if (description != "") {
        obj.description = description
      }
      // if (company != "") {
      obj.company = company
      obj.userId = userId
      obj.isActive = isActive
      obj.isLocked = isLocked
      obj.isVerfiy = isVerfiy
      // }
      // if (post != "") {
      obj.post = post
      // }
   
      if(user.role = rolesObj.COMPANY){
        obj.companyId = user?._id;
  
        }
      if(cardLinkArr && cardLinkArr.some((el) => el.link.value !="")){

        obj.cardLinkArr =[...cardLinkArr]
      }


      if (searchParams.get("id")) {
        let { data: res } = await updateCard(searchParams.get("id"), obj)
        if (res.message) {
          toastSuccess(res.message);
          HandleGeCards();
          handleEdit({});
          handleDeleteSelectedUser();
          navigate('/User/cards')

        }
      }
      else {
        let { data: res } = await addCard(obj)
        if (res.message) {
          toastSuccess(res.message);
          HandleGeCards();
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }





  const HandleGeCards = async () => {
    try {

      let query = `admin=false&skip=${page * limit}&limit=${limit}`
      if (searchQuery && searchQuery != "") {
        query = `${query}&q=${searchQuery}`
      }



      setLoading(true)
      let { data: res } = await getCard(query)
      if (res.data) {
        setCardArr(res.data)
        setLoading(false)
        setTotalElements(res.total);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }

  const HandleDeleteUsers = async (id) => {
    if (window.confirm("Are you sure you want to delete this user , once deleted the data cannot be restored") == true) {
      try {
        let { data: res } = await deleteCard(id)
        if (res.message) {
          HandleGeCards()
        }
      }
      catch (err) {
        toastError(err)
      }
    }
  }



  useEffect(() => {
    HandleGeCards()
  }, [limit, page, searchQuery])



  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handlePageChange = (page) => {
    console.log(page, "handlePageChange");
    if (totalElements / page > 1) {
      setPage(page - 1);
    }
  };


  const handleLinkChange  = (index,key,value) => {

let tempLinkArr = [...cardLinkArr];

tempLinkArr[index][key] = value;
if(key == 'link'){


  let linkObj = linkArr.find((el) => el._id == value.value);
console.log(linkObj,"linkObjlinkObj")
  if(linkObj){
tempLinkArr[index]['type'] = linkObj?.linkType;

  }
}


setCardLinkArr([...tempLinkArr])


  }


const handleRemoveLink = (inde) => {
let tempLinkArr = [...cardLinkArr];
tempLinkArr = tempLinkArr.filter((el,cin) => cin!== inde);
setCardLinkArr([...tempLinkArr])

}

const handleAddLink  = () => {
let tempLinkArr = [...cardLinkArr];

tempLinkArr.push({
  link:{
    label:"",
    value:""
  },
    name:"",
    value:"",
    type:"text"
  })

setCardLinkArr([...tempLinkArr])

}

  const debouncedSave = useCallback(
    // skipValue, limitValue, filterCondition, searchQueryValue, school, company
    debounce(
      (nextValue) =>
        HandleGeCards(),
      1000
    ),

    [searchQuery] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setSearchQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">{searchParams.get("id") ? "Edit" : "Add"} Card</h5>
              <DashboardBox>
                <form className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      User <span className="red">*</span>
                    </label>
                    <Select
                      options={userArr.map((el) => ({ label: el.name, value: el._id }))}
                      placeholder="Select from options"
                      value={userArr.map((el) => ({ label: el.name, value: el._id })).find(el => `${el.value}` == `${userId}`)}
                      onChange={(e) => {
                        setUserId(e.value);
                      }
                      }
                    />
                    {/* <input disabled={searchParams.get("id") ? true : false} onChange={(e) => setcardNumber(e.target.value)} value={cardNumber} type="number" className="form-control" /> */}
                  </div>
                  {
                    role == ROLE_ADMIN && (
                      <div className="col-12 mb-3">
                      <label className="blue-12 fs-12">
                        Card number<span className="red">*</span>
                      </label>
                      <input disabled={searchParams.get("id") ? true : false} onChange={(e) => setcardNumber(e.target.value)} value={cardNumber} type="number" className="form-control" />
                    </div>
                    )
                  }
                 
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      name<span className="red">*</span>
                    </label>
                    <input onChange={(e) => setname(e.target.value)} value={name} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      email<span className="red">*</span>
                    </label>
                    <input onChange={(e) => setemail(e.target.value)} value={email} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      company
                    </label>
                    <input onChange={(e) => setCompany(e.target.value)} value={company} type="text" className="form-control" />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      Descirpition
                    </label>
                    <input onChange={(e) => setdescription(e.target.value)} value={description} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      Post
                    </label>
                    <input onChange={(e) => setPost(e.target.value)} value={post} type="text" className="form-control" />
                  </div>
                  {
                    role == ROLE_ADMIN  && (
                      <>
                  <CustomButton
                      isBtn
                      btntype={"button"}
                      ClickEvent={() => handleAddLink()}
                      iconName={ "fa-solid fa-plus"}
                      btnName={ "Add Links"}
                    />
                  {
                    cardLinkArr && cardLinkArr.map((cardLink,cinde) => (
                        <div className='row' key={cinde}>
                               <div className="col-10 mb-3">
                                <label className="blue-12 fs-12">
                                <b> Link   {cinde+1} </b>
                                </label>
                                <Select
                                  options={linkArr.map((el) => ({ label: el.name, value: el._id }))}
                                  placeholder="Select from options"
                                  value={cardLink?.link}
                                  onChange={(val) => {
                                    handleLinkChange(cinde,'link',val);
                                  }
                                  }
                                />
                             </div>

                             {
                              cinde > 0 && (
                                <div className="col-2 mt-4">
                                <button className="btn btn-danger" type="button"  onClick={()=>handleRemoveLink(cinde)}>
                                    <i className="fa fa-times" ></i>
                                    </button>
                              </div>
                                )
                             }
                              
                             <div className="col-6 mb-3">
                                <label className="blue-12 fs-12">
                                Name
                              </label>
                                   <input onChange={(e) =>  handleLinkChange(cinde,'name',e.target.value)} value={cardLink.name} type="text" className="form-control" />
                            </div>

                             <div className="col-6 mb-3">
                                <label className="blue-12 fs-12">
                                  value                              
                                </label>

                                {
                                  cardLink.type == 'file' ? (<>
                                    
                                      <div>
                      {
                        cardLink.value && cardLink.value.includes("base64") ?
                          <img style={{ height: 50, width: 50 }} src={cardLink.value} alt="" />
                          :
                          <img style={{ height: 50, width: 50 }} src={generateFilePath(cardLink.value)} alt="" />
                      }
                    </div>
                              <FileUpload onFileChange={(e) => handleLinkChange(cinde,'value',e)} />

                                  </>) : (
                                   <input onChange={(e) =>  handleLinkChange(cinde,'value',e.target.value)} value={cardLink.value} type={`${cardLink.type}`} className="form-control" />

                                    )
                                }
                                
                            </div>


                        </div>

                      ))
                  }

                  </>

                    )}
                {/*  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      facebook
                    </label>
                    <input onChange={(e) => setfacebook(e.target.value)} value={facebook} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      phone
                    </label>
                    <input onChange={(e) => setphone(e.target.value)} value={phone} type="text" className="form-control" />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      instagram
                    </label>
                    <input onChange={(e) => setinstagram(e.target.value)} value={instagram} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      twitter
                    </label>
                    <input onChange={(e) => settwitter(e.target.value)} value={twitter} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      linkedIn
                    </label>
                    <input onChange={(e) => setlinkedIn(e.target.value)} value={linkedIn} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">
                      whatsapp
                    </label>
                    <input onChange={(e) => setwhatsapp(e.target.value)} value={whatsapp} type="text" className="form-control" />
                  </div>
*/}
                  <div className="col-4">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input checked={isActive} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" onChange={()=>setisActive(!isActive)} />
                        <span>
                          Active
                        </span>
                      </div>
                    </div>

                    <div className="col-4">
                      <label>Locked</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input checked={isLocked} className="form-check-input" type="checkbox" name="locked-status" value="option1" id="active-banner"  onChange={()=>setisLocked(!isLocked)} />
                        <span>
                          Active
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-4">
                      <label>Verify</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input checked={isVerfiy} className="form-check-input" type="checkbox" name="locked-status" value="option1" id="active-banner"  onChange={()=>setisVerfiy(!isVerfiy)} />
                        <span>
                          Active
                        </span>
                      </div>
                    </div> */}

                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">Profile Image</label>
                    <div>
                      {
                        image && image.includes("base64") ?
                          <img style={{ height: 50, width: 50 }} src={image} alt="" />
                          :
                          <img style={{ height: 50, width: 50 }} src={generateFilePath(image)} alt="" />
                      }
                    </div>
                    <FileUpload onFileChange={(e) => setimage(e)} />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">Business Image</label>
                    <div>
                      {
                        businessImage && businessImage.includes("base64") ?
                          <img style={{ height: 50, width: 50 }} src={businessImage} alt="" />
                          :
                          <img style={{ height: 50, width: 50 }} src={generateFilePath(businessImage)} alt="" />
                      }
                    </div>
                    <FileUpload onFileChange={(e) => setbusinessImage(e)} />
                  </div>



                  <div className="col-12 mb-3">
                    <label className="blue-12 fs-12">Business Cover</label>
                    <div>
                      {
                        businessCover && businessCover.includes("base64") ?
                          <img style={{ height: 50, width: 50 }} src={businessCover} alt="" />
                          :
                          <img style={{ height: 50, width: 50 }} src={generateFilePath(businessCover)} alt="" />
                      }
                    </div>
                    <FileUpload onFileChange={(e) => setbusinessCover(e)} />
                  </div>
                  <div className="col-12">

                    {
                      searchParams.get("id") &&
                      <CustomButton
                        isBtn
                        extraClass={"me-3"}
                        btntype={"button"}
                        ClickEvent={() => { handleEdit({}); handleDeleteSelectedUser(); navigate('/User/cards') }}
                        iconName="fa-solid fa-close"
                        btnName="Clear"
                      />
                    }
                    <CustomButton
                      isBtn
                      btntype={"button"}
                      ClickEvent={() => HandleCardUserAdd()}
                      iconName={searchParams.get("id") ? "fa-solid fa-edit" : "fa-solid fa-check"}
                      btnName={searchParams.get("id") ? "EDIT" : "ADD"}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Cards List</h5>
                <SearchBox value={searchQuery} onChange={(e) => handleChange(e.target.value)} extraClass='bg-white' />
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_category_columns}
                  data={cardArr}
                  pagination
                  progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalElements}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CardAdd;
