import React, { useEffect, useState } from "react";
import { updateUser } from "../../services/users.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { useLocation } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
import FileUpload from "../Utility/FileUpload";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import Switch from "@mui/material/Switch";
import "./Profilecss.css";
import { getBySlug } from "../../services/card.service";
import { images } from "../Images/Images";
import { url } from "../../services/url.service.js";
import {
  addConnectionBycCardApi,
  addaddLinkApi,
} from "../../services/connection.service";
import { Modal, Box } from "@mui/material";
function Profile1() {
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [cardLinkArr, setCardLinkArr] = useState([]);

  const [company, setCompany] = useState("");
  const [slug, setSlug] = useState("");
  const [post, setPost] = useState("");

  const [cardNumber, setcardNumber] = useState();
  const [image, setimage] = useState();
  const [name, setname] = useState();
  const [password, setpassword] = useState();
  const [facebook, setfacebook] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [twitter, settwitter] = useState();
  const [instagram, setinstagram] = useState();
  const [linkedIn, setlinkedIn] = useState();
  const [whatsapp, setwhatsapp] = useState();
  const [businessImage, setbusinessImage] = useState("");
  const [businessCover, setbusinessCover] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [ModalBox, setModalBox] = useState(false);

  const [cName, setcName] = useState("");
  const [cemail, setcemail] = useState("");
  const [cphone, setcphone] = useState("");
  const [ccompany, setccompany] = useState("");
  const [cpost, setcpost] = useState("");
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleAddPreivew = async (card) => {
    let Obj = {
      linkType: "profile",
    };
    await addaddLinkApi(card.slug, Obj);
  };

  const handleAddTap = async (linkObj) => {
    let Obj = {
      linkId: linkObj?._id,
      link: linkObj?.name,
      linkType: "link",
    };
    await addaddLinkApi(userObj.slug, Obj);
  };

  const [userObj, setUserObj] = useState({});

  const HandleGetUser = async (id) => {
    try {
      let { data: res } = await getBySlug(id);
      if (res.message) {
        console.log(res.data, "userObj");
        if (res.data?.isLocked) {
          alert("Card is Locked By User");
          window.history.back();

          return 0;
        }
        setUserObj(res?.data);
        handleAddPreivew(res?.data)
        // setcardNumber(res?.data?.cardNumber ? res?.data?.cardNumber : "");
        setCompany(res?.data?.company ? res?.data?.company : "");
        setPost(res?.data?.post ? res?.data?.post : "");
        setimage(res?.data?.image ? res?.data?.image : "");
        setname(res?.data?.name ? res?.data?.name : "");
        setbusinessImage(
          res?.data?.businessImage ? res?.data?.businessImage : ""
        );
        setbusinessCover(
          res?.data?.businessCover ? res?.data?.businessCover : ""
        );
        // setfacebook(res?.data?.facebook?.link ? res?.data?.facebook?.link : "");
        // setphone(res?.data?.phone? ? res?.data?.phone?.link : "");
        // setemail(res?.data?.email?.link ? res?.data?.email?.link : "");
        // settwitter(res?.data?.twitter?.link ? res?.data?.twitter?.link : "");
        // setinstagram(
        //   res?.data?.instagram?.link ? res?.data?.instagram?.link : ""
        // );
        // setlinkedIn(res?.data?.linkedIn?.link ? res?.data?.linkedIn?.link : "");
        // setwhatsapp(res?.data?.whatsapp?.link ? res?.data?.whatsapp?.link : "");

        if (res?.data?.cardLinkArr && res.data.cardLinkArr.length > 0) {
          setCardLinkArr(
            res.data.cardLinkArr.filter((el) => el.isActive == true)
          );
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  const saveData = (event) => {
    var phoneList = "";
    var emailList = "";
    console.log();

    var personalBio = "";
    if (document.getElementsByClassName("personalBio").length) {
      personalBio = document
        .getElementsByClassName("personalBio")[0]
        .getElementsByTagName("p")[0].innerHTML;
      personalBio = personalBio.replace(/(?:\r\n|\r|\n)/g, "\\n");
    }

    var row1 = document.getElementsByClassName("row1")[0];
    var childDivs = Array.from(row1.querySelectorAll(".wrapper"));

    console.log(personalBio, "altTextaltText");

    childDivs.forEach(function (childDiv) {
      var altText = childDiv.querySelector("img").getAttribute("alt");

      console.log(altText, "altTextaltText");
      if (altText === "Phone") {
        var onClickAttr = childDiv.querySelector("img").getAttribute("value");
        console.log(onClickAttr, "phoneList");
        var phoneNumber = onClickAttr.match(/(\+?\d+)/);
        console.log(phoneNumber, "phoneList");

        if (phoneNumber) {
          phoneList += "\nTEL;TYPE=work,voice:";
          phoneList += phoneNumber[1];
        }
      }
      if (altText === "Email") {
        var onClickAttr = childDiv.querySelector("img").getAttribute("value");
        var email = onClickAttr.match(/([^'"]+)/);
        if (email) {
          emailList += "\nEMAIL:";
          emailList += email[1];
        }
      }
    });

    console.log(phoneList, "phoneListphoneListphoneList");
    convertImageToBase64()
      .then((base64String) => {
        // create a vcard file
        var vcard =
          "BEGIN:VCARD\nVERSION:3.0\nFN:" +
          name +
          "\nN:;" +
          name +
          phoneList +
          "\nTITLE:" +
          post +
          "\nURL;TYPE=BrandCard - Digital Business Card:" +
          `https://thebrandcard.com/profile/` +
          slug +
          emailList +
          "\nORG:" +
          company +
          "\nPHOTO;ENCODING=BASE64:" +
          base64String +
          "\nNOTE:" +
          personalBio +
          "\nEND:VCARD";
        var blob = new Blob([vcard], { type: "text/vcard" });
        var url = URL.createObjectURL(blob);

        const newLink = document.createElement("a");
        newLink.download = name + ".vcf";
        newLink.textContent = name;
        newLink.href = url;

        newLink.click();
      })
      .catch((error) => {
        // Handle errors
        console.log(error);
        toastError("Unable to download vCard file. Please try again");
      });
  };

  function convertImageToBase64() {
    // Find the image element with class "profile-avatar"
    const imageElement = document.querySelector(".profile-avatar");

    if (imageElement) {
      const imageUrl = imageElement.src;

      // Create an HTML canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Create an image element
      const image = new Image();

      // Set the crossOrigin property to "Anonymous" to avoid CORS issues
      image.crossOrigin = "Anonymous";

      return new Promise((resolve, reject) => {
        // When the image is loaded, draw it on the canvas
        image.onload = function () {
          try {
            canvas.width = 96;
            canvas.height = 96;
            context.drawImage(image, 0, 0, 96, 96);

            // Get the base64 string from the canvas
            const base64String = canvas.toDataURL().split(",")[1];
            resolve(base64String);
          } catch (error) {
            console.log(error);
          }
        };

        image.onerror = function () {
          reject("");
        };

        // Set the source of the image to the image URL
        image.src = imageUrl;
      });
    } else {
      return Promise.reject("");
    }
  }

  const HandleCardUserUpdate = async () => {
    try {
      if (cName == "") {
        toastError("Name cannot be empty !!!");
        return;
      }
      if (cphone == "") {
        toastError("Name cannot be empty !!!");
        return;
      }
      if (cemail == "") {
        toastError("Email cannot be empty !!!");
        return;
      }

      if (cpost == "") {
        toastError("Post cannot be empty !!!");
        return;
      }
      if (ccompany == "") {
        toastError("Company cannot be empty !!!");
        return;
      }
      let obj = {
        name: cName,
        post: cpost,
        email: cemail,
        mobile: cphone,
        company: ccompany,
        connectionType: "profile",
      };

      let { data: res } = await addConnectionBycCardApi(userObj?._id, obj);
      if (res.message) {
        toastSuccess(res.message);

        setcName("");
        setemail("");
        setccompany("");
        setcpost("");
        setcphone("");
        setModalBox(false);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const HandleCardUserAdd = async () => {
    try {
      let obj = {};
      if (cardNumber == "") {
        toastError("Card number cannot be empty !!!");
        return;
      }
      if (name == "") {
        toastError("Name cannot be empty !!!");
        return;
      }
      if (email == "") {
        toastError("Email cannot be empty !!!");
        return;
      }

      if (cardNumber != "") {
        obj.cardNumber = cardNumber;
      }
      if (image != "") {
        obj.image = image;
      }
      if (name != "") {
        obj.name = name;
      }
      if (company != "") {
        obj.company = company;
      }
      if (post != "") {
        obj.post = post;
      }
      if (facebook != "") {
        obj.facebook = { link: facebook };
      }
      if (phone != "") {
        obj.phone = { link: phone };
      }
      if (email != "") {
        obj.email = { link: email };
      }
      if (instagram != "") {
        obj.instagram = { link: instagram };
      }
      if (twitter != "") {
        obj.twitter = { link: twitter };
      }
      if (linkedIn != "") {
        obj.linkedIn = { link: linkedIn };
      }
      if (whatsapp != "") {
        obj.whatsapp = { link: whatsapp };
      }

      let { data: res } = await updateUser(obj, userObj?._id);
      if (res.message) {
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    console.log(location, "location");
    if (location.pathname && location.pathname.split("/")[2]) {
      HandleGetUser(location.pathname.split("/")[2]);
      setSlug(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  return (
    <>
      {/* <button onClick={() => setShowForm(prev => !prev)} className='btn btn-dark' style={{ position: "absolute", top: 20, right: 20, textTransform: "uppercase", width: 90 }}>{showForm ? "edit" : "View"}</button> */}
      <section className="card_body">
        <div>
          <div>
            <main id="product-data-1" class="main">
              <div class="profile-card">
                {businessCover != "" ? (
                  businessCover && businessCover.includes("base64") ? (
                    <img src={businessCover} alt="" />
                  ) : (
                    <img src={generateFilePath(businessCover)} alt="" />
                  )
                ) : (
                  <img src={images?.card2Logo} alt="" />
                )}
              </div>
              <div className="content-card">
                <div className="d-flex " style={{ gap: 10 }}>
                  <div className="profile-image">
                    {image != "" && image ? (
                      image?.includes("base64") ? (
                        <img className="profile-avatar" src={image} alt="" />
                      ) : (
                        <img
                          className="profile-avatar"
                          src={generateFilePath(image)}
                          alt=""
                        />
                      )
                    ) : (
                      <img
                        className="profile-avatar"
                        src={images.avatar}
                        alt=""
                      />
                    )}

                    {businessImage != "" ? (
                      businessImage && businessImage.includes("base64") ? (
                        <img src={businessImage} alt="" />
                      ) : (
                        <img
                          className="company-logo"
                          src={generateFilePath(businessImage)}
                          alt=""
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="profile-details">
                    <div class="username">
                      <p>
                        {name}
                        {userObj?.isVerfiy && (
                          <img src={images.checkCircle} className="blue-tick" />
                        )}
                      </p>
                    </div>
                   
                  </div>
                  
                </div>
                <div className="d-flex2q" style={{ gap: 2, paddingLeft:'5pc' }}>
                      {post && (
                        <div class="designation">
                          <p>
                            <span>
                              {" "}
                              {post} {company ? "@" : ""}
                            </span>
                          </p>
                        </div>
                      )}
                      {company && (
                        <div class="personalBio">
                          <p>{company} </p>
                        </div>
                      )}
                    </div>
                {userObj?.description && (
                  <div class="designation" style={{ marginTop: "-10px" }}>
                    <p>{userObj?.description}</p>
                  </div>
                )}

                <div class="profile-button" id="profile-button">
                  <button class="connect-btn" onClick={() => setModalBox(true)}>
                    Connect with me
                  </button>
                  <button class="connect-btn" onClick={() => saveData(this)}>
                    Save to phone
                  </button>
                </div>
              </div>

              {cardLinkArr && cardLinkArr.length > 0 && (
                <div class="social-media-links">
                  <div class="row1">
                    {cardLinkArr.map((el) => (
                      <div class="wrapper">
                        <img
                          src={
                            el.linkObj.image != ""
                              ? generateFilePath(el.linkObj.image)
                              : ""
                          }
                          alt={el.linkObj.name}
                          value={el.value}
                          onClick={() => {
                            handleAddTap(el.linkObj);

                            el.type == "file" &&
                              window.open(
                                `${url}/uploads/${el.value}`,
                                "_self"
                              );

                            el.type == "text" &&
                              (!el.linkObj.link
                                ? window.open(`${el.value}`, "_self")
                                : window.open(
                                    `${el.linkObj.link}${el.value}`,
                                    "_self"
                                  ));
                            el.type == "number" &&
                              el.linkObj.link &&
                              window.open(
                                `${el.linkObj.link}${el.value}`,
                                "_self"
                              );
                          }}
                        />

                        <span class="alt-text" style={{ fontSize: 12 }}>
                          {el.linkObj.name?.length > 10
                            ? el?.linkObj.name.substring(0, 10) + "..."
                            : el?.linkObj.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </section>

      <Modal
        open={ModalBox}
        onClose={() => setModalBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div className="modal-container modaicon">
            <div className="modal-header">
              <h5>Share your info with {name}</h5>
              <CustomButton
                isBtn
                btntype="button"
                iconName="fa fa-times text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-bodyd">
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <label className="blue-12 fs-12">
                    Name<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={cName}
                    onChange={(e) => setcName(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-12 mb-3">
                  <label className="blue-12 fs-12">
                    Mobile<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={cphone}
                    onChange={(e) => setcphone(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-12 mb-3">
                  <label className="blue-12 fs-12">
                    Email<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={cemail}
                    onChange={(e) => setcemail(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-12 mb-3">
                  <label className="blue-12 fs-12">
                    Designation<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={cpost}
                    onChange={(e) => setcpost(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-12 mb-3">
                  <label className="blue-12 fs-12">
                    Company<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={ccompany}
                    onChange={(e) => setccompany(e.target.value)}
                  />
                </div>

                <CustomButton
                  isBtn
                  btntype={"button"}
                  iconName={"fa-solid fa-check"}
                  btnName={"ADD"}
                  ClickEvent={() => HandleCardUserUpdate()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Profile1;
