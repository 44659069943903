import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill"; // ES6
import { addPage, getPageById, getPageBySlug, updatePage } from "../../services/page.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";

import { useSearchParams } from "react-router-dom";
function PrivacyPolicy() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [PageObj, setPageObj] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = async () => {
    try {
      let obj = {
        title,
        description,
      };
      if (PageObj?._id) {
        let { data: res } = await updatePage(obj, PageObj?._id);
        if (res) {
          toastSuccess(res.message);
        }
      } else {
        let { data: res } = await addPage(obj);
        if (res) {
          toastSuccess(res.message);
        }
      }
      console.log(obj);
    } catch (error) {
      toastError(error);
    }
  };

  const handlegetPage = async () => {
    try {
      const { data: res } = await getPageBySlug('privacy-policy');
      if (res) {
        setPageObj(res?.data);
        setTitle(res?.data?.title);
        setDescription(res?.data?.description);
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
      handlegetPage();
  }, []);

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form className="form">
            <h5 className="blue-1 mb-4">{PageObj?._id ? "Update" : "Add"} Privacy Policy</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Post Info</h5>
                  
                  
                      <div className="col-12">
                        <label>
                          DESCRIPTION<span className="red">*</span>
                        </label>
                        <QuillEditor theme="snow"  value={description} handleChange={(e) => setDescription(e)} />
                      </div>
                    <div className="col-12">
                      <CustomButton btntype="button" ClickEvent={() => handleSubmit()} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>
         
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default PrivacyPolicy;
