import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { usersGet } from "../../redux/actions/Users/users.actions";
import { BulkProductUploadToBackend, sampleProductFileDownload } from "../../services/product.service";
import { rolesObj } from "../../utils/roles";
import { errorHandleEr, toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import BulkProductUpdate from "./BulkProductUpdate";

function BulkProductUpload() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isSeller, setIsSeller] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState("");
  const [selectedSellerObj, setSelectedSellerObj] = useState({});

  const [file, setFile] = useState("");
  const userArr = useSelector((state) => state.users.users);

  const getSellerUsers = () => {
    if (authObj.role == rolesObj.SELLER) {
      setIsSeller(true);
    } else {
      dispatch(usersGet("role=ADMIN"));
    }
  };

  useEffect(() => {
    getSellerUsers();
  }, []);

  const handleSampleFileDownload = async () => {
    try {
      if (selectedSellerObj?._id || authObj?.role == rolesObj?.SELLER) {
        let tempObj = {
          sellerId: "",
        };
        if (authObj?.role == rolesObj?.SELLER) {
          tempObj.sellerId = authObj?.user?._id;
        } else {
          tempObj.sellerId = selectedSellerObj?._id;
        }
        await sampleProductFileDownload(JSON.stringify(tempObj));
      } else {
        toastError({ message: "Please Select Seller" });
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmitBulkUploadProduct = async () => {
    try {
      if (!file) {
        toastError("File not found ! please select file");
      } else if (file == "") {
        toastError("File not found !, please select file");
      }
      let formData = new FormData();
      formData.append("excel", file);
      setLoading(true)
      let { data: res } = await BulkProductUploadToBackend(formData);
      if (res.success) {
        alert(res.message);
        setLoading(false)
      }
    } catch (error) {
        toastError(error)
        setLoading(false)

    }
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <DashboardBox>
            <h5 className="blue-1 mb-4">Bulk Product Upload</h5>
            <form action="" className="form row">
              {!isSeller && (
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Select Vendor <span className="red">*</span>
                  </label>
                  {userArr && userArr.length > 0 && (
                    <Select onChange={(e) => setSelectedSellerObj(e)} value={selectedSellerObj} options={userArr && userArr.length > 0 ? userArr.map((el) => ({ ...el, label: el.name, value: el._id })) : []} />
                  )}
                </div>
              )}
              <div className="col-12 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label>CSV FILE</label>
                  <CustomButton isBtn btntype="button" ClickEvent={() => handleSampleFileDownload()} downloadAble noIcon changeClass="btn p-0 m-0 fs-12 text-primary border-0" btnName="SAMPLE FILE DOWNLOAD" />
                </div>
                <FileUpload
                  returnOriginal={true}
                  onFileChange={(e) => {
                    setFile(e);
                    console.log(e, "e");
                  }}
                />
                <div className="form-text fs-12 blue-1">PLEASE DOWNLOAD THE SAMPLE FILE INPUT YOUR DESIRE INFORMATION THEN UPLOAD. DON'T TRY TO UPLOAD DIFFERENT FILE FORMAT AND INFORMATION</div>
              </div>
              <div className="col-12 text-center">
                {
                  isLoading ? (     <CustomButton btntype="button"  isBtn iconName="fa-solid fa-check" btnName="Please Wait" />) : (     <CustomButton btntype="button" ClickEvent={() => handleSubmitBulkUploadProduct()} isBtn iconName="fa-solid fa-check" btnName="Save" />)
                }
           
              </div>
            </form>
          </DashboardBox>
        </div>
        <div className="mt-4">
          <BulkProductUpdate />
        </div>
      </section>
    </main>
  );
}

export default BulkProductUpload;
