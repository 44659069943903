import React, { useState } from "react";

function SearchBox(props) {
  // console.log(props, "@@");
  const [searchStr, setSearchStr] = useState("");

  const handleSearchStr = (e) => {
    setSearchStr(e);
    props.handleChange(e);
  };

  return (
    <div className="search-field">
      <form className="form">
        <div className={props.extraClass ? `input-group ${props.extraClass}` : "input-group"}>
          <div className="input-group-text">
            <i className="ion-ios-search-strong blue-1"></i>
          </div>
          <input value={searchStr} type="text" onChange={(e) => handleSearchStr(e.target.value)} className="form-control" placeholder="Search" />
        </div>
      </form>
    </div>
  );
}

export default SearchBox;
