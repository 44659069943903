import { addSellerCategory, deleteSellerCategoryById, getSellerCategorys, updateSellerCategoryById } from "../../../services/sellerCategory.service";

export const SELLER_CATEGORY_ADD = "SELLER_CATEGORY_ADD";
export const SELLER_CATEGORY_ADD_SUCCESS = "SELLER_CATEGORY_ADD_SUCCESS";
export const SELLER_CATEGORY_ADD_FAIL = "SELLER_CATEGORY_ADD_FAIL";

export const GET_ALL_SELLER_CATEGORYS = "GET_ALL_SELLER_CATEGORYS";
export const GET_ALL_SELLER_CATEGORYS_SUCCESS = "GET_ALL_SELLER_CATEGORYS_SUCCESS";
export const GET_ALL_SELLER_CATEGORYS_FAIL = "GET_ALL_SELLER_CATEGORYS_FAIL";

export const UPDATE_SELLER_CATEGORY_BY_ID = "UPDATE_SELLER_CATEGORY_BY_ID";
export const UPDATE_SELLER_CATEGORY_BY_ID_SUCCESS = "UPDATE_SELLER_CATEGORY_BY_ID_SUCCESS";
export const UPDATE_SELLER_CATEGORY_BY_ID_FAIL = "UPDATE_SELLER_CATEGORY_BY_ID_FAIL";

export const SET_SELLER_CATEGORY_OBJ = "SET_SELLER_CATEGORY_OBJ";
export const SET_SELLER_CATEGORY_OBJ_SUCCESS = "SET_SELLER_CATEGORY_OBJ_SUCCESS";
export const SET_SELLER_CATEGORY_OBJ_FAIL = "SET_SELLER_CATEGORY_OBJ_FAIL";

export const GET_SELLER_CATEGORY_BY_ID = "GET_SELLER_CATEGORY_BY_ID";
export const GET_SELLER_CATEGORY_BY_ID_SUCCESS = "GET_SELLER_CATEGORY_BY_ID_SUCCESS";
export const GET_SELLER_CATEGORY_BY_ID_FAIL = "GET_SELLER_CATEGORY_BY_ID_FAIL";

export const DELETE_SELLER_CATEGORY_BY_ID = "DELETE_SELLER_CATEGORY_BY_ID";
export const DELETE_SELLER_CATEGORY_BY_ID_SUCCESS = "DELETE_SELLER_CATEGORY_BY_ID_SUCCESS";
export const DELETE_SELLER_CATEGORY_BY_ID_FAIL = "DELETE_SELLER_CATEGORY_BY_ID_FAIL";

// export const GET_ALL_NESTED_CATEGORIES = "GET_ALL_NESTED_CATEGORIES";
// export const GET_ALL_NESTED_CATEGORIES_SUCCESS = "GET_ALL_NESTED_CATEGORIES_SUCCESS";
// export const GET_ALL_NESTED_CATEGORIES_FAIL = "GET_ALL_NESTED_CATEGORIES_FAIL";

export const sellerCategoryAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SELLER_CATEGORY_ADD });
    let { data: response } = await addSellerCategory(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: SELLER_CATEGORY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(sellerCategoryGet())
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SELLER_CATEGORY_ADD_FAIL, payload: err });
  }
};

export const sellerCategoryGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SELLER_CATEGORYS });
    let { data: response } = await getSellerCategorys(formData);
    if (response) {
      dispatch({
        type: GET_ALL_SELLER_CATEGORYS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SELLER_CATEGORY_ADD_FAIL, payload: err });
  }
};

export const SetsellerCategoryObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELLER_CATEGORY_OBJ });
    if (formData) {
      dispatch({
        type: SET_SELLER_CATEGORY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SELLER_CATEGORY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SELLER_CATEGORY_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const sellerCategoryUpdate = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SELLER_CATEGORY_BY_ID });
    let { data: response } = await updateSellerCategoryById(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_SELLER_CATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(sellerCategoryGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_SELLER_CATEGORY_BY_ID_FAIL, payload: err });
  }
};

export const sellerCategoryDelete = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SELLER_CATEGORY_BY_ID });
    let { data: response } = await deleteSellerCategoryById(formData, id);
    if (response) {
      console.log(response.message);
      dispatch({
        type: DELETE_SELLER_CATEGORY_BY_ID_SUCCESS,
        payload: response.message
      });
      dispatch(sellerCategoryGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_SELLER_CATEGORY_BY_ID_FAIL, payload: err });
  }
};

