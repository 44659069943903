import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/card";


export const addCard = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addCard", formData);
};

export const getCard = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getCard?${query}`);
};
export const updateCard = (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateCard/${id}`, obj);
};



export const getBySlug = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getBySlug/${id}`);
};
export const deleteCard = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
