import { Route, Routes, useLocation } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import CardAdd from "../components/Blog/AddCard";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SideBar from "../components/Sidebar/SideBar";
import Dashboard from "../components/dashboard/Dashboard";
import Profile from "../components/Profile/Profile";

import { useEffect } from "react";
import AddUser from "../components/User/AddUser";
import AddTemplate from "../components/Template/AddTemplate";
import CardLink from "../components/CardLink/CardLink";
import CardLinkCategory from "../components/CardLink/CardLinkCategory";
import Profile1 from "../components/Profile1/Profile";
import AddBlankCard from "../components/Blog/AddBlankCard.jsx";
import Insight from "../components/insights/Insight.jsx";
import Verfication from "../components/Verfication/Verfication.jsx";
import ShowVerfication from "../components/Verfication/ShowVerfication.jsx";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";
import TrackOrder from "../components/Order/TrackOrder/TrackOrder";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import UpdateProductListPage from "../components/Products/AddProduct/UpdateProductListPage";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";
import BulkProductUpload from "../components/Products/BulkProductUpload";
import Category from "../components/Products/Category/Category";
import ProductList from "../components/Products/ProductList";
import ProductReview from "../components/Review/ProductReview";
import AddReview from "../components/Review/AddReview";
import Coupon from "../components/Coupon/Coupon";
import Subscriber from "../components/Subscriber/Subscriber";
import Setting from "../components/SystemSetting/Setting";
import Contact from "../components/Frontend-CMS/Contacts/Contact";
import ViewSeo from "../components/Seo/ViewSeo";
import AddSeo from "../components/Seo/AddSeo";
import AddBanner from "../components/Frontend-CMS/Banners/AddBanner";
import Banners from "../components/Frontend-CMS/Banners/Banners";
import AddClient from "../components/Frontend-CMS/Client/AddClient";
import Clients from "../components/Frontend-CMS/Client/Clients";
import AddTestimonial from "../components/Frontend-CMS/Testimonial/AddTestimonial";
import Testimonials from "../components/Frontend-CMS/Testimonial/Testimonials";

import BlogCategory from "../components/Blog/BlogCategory";
import AddBlog from "../components/Blog/AddBlog";
import Blog from "../components/Blog/Blog";
import Homepage from "../components/Frontend-CMS/Homepage.jsx";
import TermsConditions from "../components/Page/TermsConditions.jsx";
import RefundPolicy from "../components/Page/RefundPolicy.jsx";
import PrivacyPolicy from "../components/Page/PrivacyPolicy.jsx";
import DesignList from "../components/Products/DesignList.jsx";
import { useSelector } from "react-redux";

export default function AuthorizedRoutes() {
  let role = useSelector((state) => state.auth.role);

  const location = useLocation();
  useEffect(() => {}, [location.pathname]);
  return (
    <>
      {location.pathname.includes("profile") ? (
        <Routes>
          <Route path="/profile/:slug" element={<Profile1 />}></Route>
        </Routes>
      ) : (
        <section className="body_bg">
          <div className="row g-0">
            <div className="col-12 col-md-2" style={{ contain: "content" }}>
              <SideBar />
            </div>
            <div
              className="col-12 col-md-10"
              style={{ height: "100vh", overflow: "hidden scroll" }}
            >
              <Header />
              <Routes>
                <Route exact path="/Dashboard" element={<Dashboard />}></Route>
                <Route path="/insight/:slug" element={<Insight />}></Route>
                <Route exact path="/User/cards" element={<CardAdd />}></Route>
                <Route
                  exact
                  path="/User/blank-cards"
                  element={<AddBlankCard />}
                ></Route>
                <Route exact path="/User/users" element={<AddUser />}></Route>

                {role == "ADMIN" && (
                  <>
                    <Route
                      exact
                      path="/Template/templates"
                      element={<AddTemplate />}
                    ></Route>
                    <Route
                      exact
                      path="/Cardlink/card-links"
                      element={<CardLink />}
                    ></Route>
                    <Route
                      exact
                      path="/Cardlink/category"
                      element={<CardLinkCategory />}
                    ></Route>
                    <Route
                      exact
                      path="/Verfication/verfication"
                      element={<Verfication />}
                    ></Route>
                    <Route
                      exact
                      path="/Verfication/show-verifications"
                      element={<ShowVerfication />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Category"
                      element={<Category />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Update-Product"
                      element={<UpdateProductListPage />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Attribute"
                      element={<Attribute />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Attribute-Value"
                      element={<AttributeValue />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/AddProduct"
                      element={<AddProduct />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Bulk-Product-Upload"
                      element={<BulkProductUpload />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Product-List"
                      element={<ProductList />}
                    ></Route>
                    <Route
                      exact
                      path="/Product/Design-List"
                      element={<DesignList />}
                    ></Route>
                    <Route
                      exact
                      path="/System-Setting/Setting"
                      element={<Setting />}
                    ></Route>
                    <Route
                      exact
                      path="/Subscriber"
                      element={<Subscriber />}
                    ></Route>
                    <Route exact path="/Contact" element={<Contact />}></Route>
                    <Route
                      exact
                      path="/Order/Total-Order"
                      element={<TotalOrder />}
                    ></Route>
                    <Route
                      exact
                      path="/Seo/View-Seo"
                      element={<ViewSeo />}
                    ></Route>
                    <Route
                      exact
                      path="/Seo/Add-Seo"
                      element={<AddSeo />}
                    ></Route>
                    <Route exact path="/Banners" element={<Banners />}></Route>
                    <Route
                      exact
                      path="/Banners/Banner-Create"
                      element={<AddBanner />}
                    ></Route>
                    <Route exact path="/Clients" element={<Clients />}></Route>
                    <Route
                      exact
                      path="/Clients/Client-Create"
                      element={<AddClient />}
                    ></Route>
                    <Route
                      exact
                      path="/Testimonials"
                      element={<Testimonials />}
                    ></Route>
                    <Route
                      exact
                      path="/Testimonials/Testimonial-Create"
                      element={<AddTestimonial />}
                    ></Route>
                    <Route
                      exact
                      path="/Coupon/Coupon"
                      element={<Coupon />}
                    ></Route>
                    <Route exact path="/Blog/post" element={<Blog />}></Route>
                    <Route
                      exact
                      path="/Blog/post/create"
                      element={<AddBlog />}
                    ></Route>
                    <Route
                      exact
                      path="/Blog/Category"
                      element={<BlogCategory />}
                    ></Route>
                    <Route
                      exact
                      path="/HomepageCMS"
                      element={<Homepage />}
                    ></Route>
                    <Route
                      exact
                      path="/Privacy-Policy"
                      element={<PrivacyPolicy />}
                    ></Route>
                    <Route
                      exact
                      path="/Refund-Policy"
                      element={<RefundPolicy />}
                    ></Route>
                    <Route
                      exact
                      path="/Terms-Conditions"
                      element={<TermsConditions />}
                    ></Route>
                  </>
                )}
              </Routes>
              <Footer />
            </div>
          </div>
        </section>
      )}
    </>
  );
}
