import React, { useState } from "react";
import { BulkAttributeValueExcelUpload, sampleAttributeValueFileDownload } from "../../../services/Attribute.service";
import { toastError } from "../../../utils/toastUtils";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { toastSuccess } from "../../Utility/ToastUtils";

function BulkAttributeValue() {
  const [imageStr, setImageStr] = useState(null);
  const handleFileSet = (value) => {
    setImageStr(value);
  };
  const handleSampleFileDownload = async () => {
    try {
      await sampleAttributeValueFileDownload();
    } catch (error) {
      toastError(error);
    }
  };
  const handleUpload = async () => {
    try {
      let formData = new FormData();
      formData.append("excel", imageStr);
      const { data: res } = await BulkAttributeValueExcelUpload(formData);
      if (res) {
        toastSuccess(res.message);
      }
    } catch (error) {
      toastError(error);
    }
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <DashboardBox>
            <h5 className="blue-1 mb-4">Bulk Upload</h5>
            <form className="form row">
              <div className="col-12 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label>CSV FILE</label>
                  <CustomButton isBtn btntype="button" ClickEvent={() => handleSampleFileDownload()} downloadAble noIcon changeClass="btn p-0 m-0 fs-12 text-primary border-0" btnName="SAMPLE FILE DOWNLOAD" />
                </div>
                <FileUpload returnOriginal={true} onFileChange={handleFileSet} />
                <div className="form-text fs-12 blue-1">PLEASE DOWNLOAD THE SAMPLE FILE INPUT YOUR DESIRE INFORMATION THEN UPLOAD. DON'T TRY TO UPLOAD DIFFERENT FILE FORMAT AND INFORMATION</div>
              </div>
              <div className="col-12 text-center">
                <CustomButton isBtn iconName="fa-solid fa-check" btntype="button" ClickEvent={() => handleUpload()} btnName="Save" />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default BulkAttributeValue;
